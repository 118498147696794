
@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap");
.App {
  text-align: center;
  font-family: "Anonymous Pro", monospace;
  color: #F69;
  font-size:18px;
}

*{
  box-sizing: border-box;
}

body{
  overflow: hidden;
}

.overlay {
  opacity: 0.69;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.bg{
  z-index: -1;
  position: absolute;
  top: 0;
  left:0;
  right:0;
  bottom:0;
  background-color: #F69;
  background-image: url("./assets/bg.gif");
  background-blend-mode: darken;
  background-size: 256px 256px;
  background-repeat: repeat;
}

.modal{
  background-color: #F69;
  width : 50%;
  height : 60%;
  align-content: center;
  margin-left: 25%;
  margin-top: 120px;
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: center;
  color: yellow;
  font-family: "Anonymous Pro", monospace;;
}

